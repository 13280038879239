<!--账户的查看金额详情的弹窗-->
<template>
  <el-dialog
      title="该账户金额详情"
      :visible.sync="show"
      width="80%"
      @close="close"
      custom-class="vDialog"
      center>
    <el-row >

      <el-table
          :data="parentTableData"
          style="width: 100%;">
        <el-table-column
            label="结算账户"
            width="160"
            prop="settlementAccount">
        </el-table-column>
        <!--        <el-table-column-->
        <!--            label="账户类型"-->
        <!--            prop="accountTypeName"
            label="结算账户">-->
        <!--        </el-table-column>-->
        <!--        <el-table-column-->
        <!--            label="户名"-->
        <!--            prop="accountName"-->
        <!--        >-->
        <!--        </el-table-column>-->
        <!--        <el-table-column-->
        <!--            label="结算账号"-->
        <!--            prop="accountNumber"-->
        <!--        >-->
        <!--        </el-table-column>-->

        <el-table-column
            label="所属店铺"
            width="120"
            prop="shopName">
        </el-table-column>

        <el-table-column
            label="上次对账时间"
            prop="lastTime"
            width="200"
            align="center">
        </el-table-column>
        <el-table-column
            label="上次对账金额"
            prop="lastTimeAmount"
            width="130"
            align="right">
        </el-table-column>
        <el-table-column
            label="已确认收款"
            prop="confirmProceeds"
            width="130"
            align="right">
        </el-table-column>
        <el-table-column
            label="已确认支出"
            prop="confirmSpend"
            width="130"
            align="right">
        </el-table-column>
        <el-table-column
            label="期末结余"
            prop="closeBalance"
            width="130"
            align="right">
        </el-table-column>

      </el-table>

      <el-row style="margin-left: 10px;margin-top: 10px">
<!--        <i class="el-icon-date" style="font-size: 30px"> {{ this.account.settlementAccount }}</i>-->
        <h1 style="font-size: 20px">{{ this.account.settlementAccount }}</h1>
      </el-row>

      <template>
        <el-table
            :data="tableData4"
            :row-style="{height: '50px'}"
            style="width: 1000px"
            ref="refTable4"
            @row-click="hoverRows"
            :summary-method="getSummaries"
            :row-class-name="getRowClass"
            align="center">
          <el-table-column type="expand">
            <template slot-scope="props">
              <el-table :data="props.row.children"
                        ref="refTable41"
                        @row-click="hoverRows"
                        :show-header="false"
                        :row-class-name="getRowClass"
                        :cell-style="childrenRol">
                <el-table-column label="" prop="projectName"
                                 align="center"></el-table-column>
                <el-table-column label="" prop="items" width="300"
                                 align="left"></el-table-column>
                <el-table-column label="" prop="litres" width="100"
                                 align="right"></el-table-column>
              </el-table>
            </template>
          </el-table-column>
          <el-table-column
              label=""
              prop="foldName"
              align="center">
          </el-table-column>
          <el-table-column
              label=""
              prop="items"
              align="center">
          </el-table-column>
          <el-table-column
              label=""
              prop="litres"
              align="right">
          </el-table-column>
        </el-table>
      </template>


    </el-row>
  </el-dialog>


</template>

<script>
export default {
  name: "account-money-details",
  props: {
    visible: {
      required: true,
      default: false,
      type: Boolean
    },
    account: {
      type: Object,
      required: true
    }
  },
  computed: {
    show: {
      get() {
        return this.visible
      },
      set(value) {
        this.$emit('update:visible', value)
      }
    }
  },
  data() {
    return {

      // parentTableData:  this.account,  //这样写 报错，要用下面这种写法。
      parentTableData: [
        {
          accountId: this.account.id,
          settlementAccount: this.account.settlementAccount,
          // accountTypeId: this.account.accountTypeId,
          accountTypeName: this.account.accountTypeName,
          accountName: this.account.accountName,
          accountNumber: this.account.accountNumber,
          shopId: this.account.shopId,
          shopName: this.account.shopName,
          lastTime: this.account.lastTime,
          lastTimeAmount: this.account.lastTimeAmount,
          confirmProceeds: this.account.confirmProceeds,
          confirmSpend: this.account.confirmSpend,
          closeBalance: this.account.closeBalance,
          isValid: this.account.isValid,
          // accountTypeName: 444
        },
      ],


      accountTypeArray: [],
      shopArray: [{
        'name': '全部店铺',
        'value': 0
      }],

      tableData4: [],

      // accountIds: [],
      // timeList: [],

    }
  },
  created() {
    // console.log("this.account11111")
    // console.log(this.account)
    //
    // // this.accountIds.push(this.account.id);
    // this.timeList.push(this.account.lastTime);
    // this.timeList.push(this.$dateUtils.getTimeStr('d')  + " 23:59:59");
    // console.log("this.accountIds")
    // console.log(this.accountIds)
    // console.log("this.timeList")
    // console.log(this.timeList)

    this.queryMoneyDetails()
    // this.queryShopIds()
    // this.queryAccountTypeIds()
  },
  methods: {
    queryMoneyDetails() {
      this.$axios({
        method: 'get',
        url: '/account/queryMoneyDetails',
        params: {
          tenantCrop: localStorage.getItem('tenantCrop'),
          openBalance: this.account.lastTimeAmount, //起初结余 就是表格里的上次对账金额
          accountId: this.account.id,
          lastTime: this.account.lastTime,
          deadline: this.$dateUtils.getTimeStr('d')  + " 23:59:59",

          //要传 list 或 数组，后端得写个DTO来接
          // accountId: this.accountIds, //账户id(只加一个的)
          // timeList: this.timeList, //时间区间
        }
      }).then(response => {
        console.log("response2222")
        console.log(response)

        if (response.data.code === 200) {
          // this.total = response.data.total
          this.tableData4 = response.data.data
        } else {
          this.$message.error(response.data.msg)
        }

        // this.tableData4 = response.date.date;
      })
    },


    queryShopIds() {
      this.$selectUtils.shopIdsIsValid().then(response => {
        this.shopArray.push(...JSON.parse(response.data.data))
      })
    },
    queryAccountTypeIds() {
      this.$selectUtils.queryAccountTypeIds().then(response => {
        this.accountTypeArray = JSON.parse(response.data.data)
      })
    },
    close() {
      this.show = false
    },

    hoverRows(row) {
      if (row.children == null) {
        return;
      }
      //做一二级渠道的判断是因为 打开一级渠道后  别的二级渠道是 undefined
      if (row.children.length > 0) {
        if (row.grade === 1) {
          // this.$refs.refTable1.toggleRowExpansion(row);
          this.$refs.refTable4.toggleRowExpansion(row);
        } else {
          // if (this.$refs.refTable11 !== undefined) {
          //     this.$refs.refTable11.toggleRowExpansion(row);
          // }
          if (this.$refs.refTable41 !== undefined) {
            this.$refs.refTable41.toggleRowExpansion(row);
          }
        }
      }
    },

    //合计行
    getSummaries(param) {
      const {columns, data} = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0 || index === 3) {
          sums[index] = '';
          return;
        }
        if (index === 1) {
          sums[index] = '合计';
          return;
        }
        const values = data.map(item => Number(item[column.property]));

        sums[index] = values.reduce((prev, curr) => {
          const value = Number(curr);
          if (!isNaN(value)) {
            return prev + curr;
          } else {
            return prev;
          }
        }, 0);
        sums[index] += ' ';
      });
      return sums;
    },
    getRowClass(row) {
      // console.log("row");
      // console.log(row)
      // console.log("length")
      // console.log(row.row.children.length)
      let res = [];

      if (null == row.row.children || row.row.children.length === 0) {  //判断当前行是否有子数据或者根据实际情况设置
        res.push('row-expand-unhas');
      }
      // if (row.row.litres === 0 || row.row.litres === "0") {
      //     res.push('zeroDisplay');
      // }
      return res;
    },

    //改变子表格字体颜色
    childrenRol() {
      return "color: #4c8daa;";
    },


  }
}
</script>

<style>
.main_btn > p {
  height: 40px;
}

.row-expand-unhas .el-table__expand-column {
  pointer-events: none;
}

.row-expand-unhas .el-table__expand-column .el-icon {
  visibility: hidden;
}

.vDialog .el-dialog__body {

  padding-top: 0px !important;

}

</style>

<style scoped>

</style>