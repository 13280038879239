<template>
  <div>
<!--    <fieldset style="width:97%">-->
<!--      <legend>搜索信息</legend>-->
<!--      <el-form :inline="true" class="demo-form-inline">-->
<!--        <el-form-item>-->
<!--          <el-date-picker-->
<!--              v-model="lastTime"-->
<!--              value-format="yyyy-MM-dd"-->
<!--              type="date"-->
<!--              placeholder="选择日期"-->
<!--              align="right"-->
<!--              :picker-options="pickerOptions">-->
<!--          </el-date-picker>-->
<!--        </el-form-item>-->

<!--        <el-form-item>-->
<!--          &lt;!&ndash;        <el-button type="primary" @click="onSubmit">查询</el-button>&ndash;&gt;-->
<!--          <el-button type="primary" @click="queryAccountList()">查询</el-button>-->
<!--&lt;!&ndash;          <el-button type="primary">查看今天</el-button>&ndash;&gt;-->
<!--        </el-form-item>-->
<!--      </el-form>-->
<!--    </fieldset>-->
    <el-row style="margin-left: 10px;margin-top: 10px">
      <i class="el-icon-date" style="font-size: 30px"> {{ lastTime }} {{ this.$dateUtils.getWeekDay(lastTime) }}
        各账户情况</i>
    </el-row>

    <el-row style="margin-top: 20px;padding-left: 10px">
      <el-button type="primary" @click="addAccount">新增账户</el-button>
    </el-row>
    <el-row style="margin-top: 20px; margin-bottom: 20px">
      <el-table
          :data="tableData"
          border
          row-key="id"
          :cell-style="cellStyleChange"
          :tree-props="{children: 'children', hasChildren: true}"
          style="width: 100%">

        <el-table-column
            label="账户类型"
            prop="accountTypeName"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.hasChildren">{{ scope.row.accountTypeName }}</span>
            <span v-if="!scope.row.hasChildren">-</span>
          </template>
        </el-table-column>
        <el-table-column
            label="结算账户"
            width="130"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.settlementAccount }}</span>
          </template>
        </el-table-column>
        <el-table-column
            label="户名"
            prop="accountName"
        >
        </el-table-column>
        <el-table-column
            label="结算账号"
            prop="accountNumber"
        >
        </el-table-column>
        <el-table-column
            label="上次对账时间"
            prop="lastTime"
            width="200"
            align="center">
        </el-table-column>
        <el-table-column
            label="上次对账金额"
            prop="lastTimeAmount"
            width="130"
            align="right">
        </el-table-column>
        <el-table-column
            label="已确认收款"
            prop="confirmProceeds"
            width="130"
            align="right">
        </el-table-column>
        <el-table-column
            label="已确认支出"
            prop="confirmSpend"
            width="130"
            align="right">
        </el-table-column>
        <el-table-column
            label="期末结余"
            prop="closeBalance"
            width="130"
            align="right">
        </el-table-column>

        <el-table-column
            label="是否激活"
        >
          <template slot-scope="scope">
            <div v-if="!scope.row.hasChildren">
              <el-switch
                  v-model="scope.row.isValid"
                  :active-value="1"
                  :inactive-value="0"
                  @change="handleValidChange(scope.row)"
                  active-color="#13ce66"
                  inactive-color="#ff4949">
              </el-switch>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="300">
          <template slot-scope="scope">
            <div v-if="!scope.row.hasChildren">
              <el-button
                  size="mini"
                  type="primary"
                  @click="moneyDetails(scope.row)">查看详情
              </el-button>
              <el-button
                  size="mini"
                  type="primary"
                  @click="handleEdit(scope.row)">编辑
              </el-button>
              <el-popconfirm
                  title="这是一段内容确定删除吗？"
                  @confirm="handleDelete(scope.row.id)"
              >
                <el-button
                    size="mini"
                    type="danger"
                    slot="reference"
                    style="margin-left: 20px"
                >删除
                </el-button>
              </el-popconfirm>
            </div>

          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <account-add
        :visible.sync="addVisible"
        @close="close">
    </account-add>
    <account-edit
        v-if="editVisible"
        :visible.sync="editVisible"
        :account="account"
        @close="close">
    </account-edit>
    <account-money-details
        v-if="moneyDetailsVisible"
        :visible.sync="moneyDetailsVisible"
        :account="account"
        @close="close"
        account-type="">
    </account-money-details>
  </div>
</template>

<script>
import AccountAdd from "@/pages/spend/account/account-add";
import AccountEdit from "@/pages/spend/account/account-edit";
import AccountMoneyDetails from "@/pages/spend/account/account-money-details";

export default {
  name: "account-list",
  components: {AccountAdd, AccountEdit, AccountMoneyDetails},
  data() {
    return {
      addVisible: false,
      editVisible: false,
      moneyDetailsVisible: false,
      tableData: [],
      account: '',

      lastTime: this.$dateUtils.getTimeStr('d'),

      pickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', new Date());
          }
        }, {
          text: '昨天',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', date);
          }
        }, {
          text: '一周前',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', date);
          }
        }]
      },

    }
  },
  created() {
    this.queryAccountList()
  },
  methods: {


    // 添加账号
    addAccount() {
      this.addVisible = true
    },
    handleSizeChange() {

    },
    handleCurrentChange() {

    },
    moneyDetails(val) {
      this.account = val
      this.moneyDetailsVisible = true
    },
    handleEdit(val) {
      this.account = val
      this.editVisible = true
    },
    handleDelete(id) {
      this.$axios({
        method: 'GET',
        url: '/account/deleteAccount',
        params: {
          id: id
        }
      }).then(response => {
        if (response.data.code === 200) {
          this.$message.success('删除成功')
          this.queryAccountList()
        }
      })
    },
    handleValidChange(val) {
      const data = {
        id: val.id,
        isValid: val.isValid
      }
      this.editAccount(data)
    },
    // 查询
    queryAccountList() {
      this.$axios({
        method: 'GET',
        url: '/account/queryAccountByDeadlineTree',
        params: {
          tenantCrop: localStorage.getItem('tenantCrop'),
          deadline: this.lastTime + " 23:59:59",
        }
      }).then(response => {
        if (response.data.code === 200) {
          // this.total = response.data.total
          this.tableData = response.data.data
        } else {
          this.$message.error(response.data.msg)
        }
      })
    },
    // 修改账户列表
    editAccount(data) {
      this.$axios({
        method: 'POST',
        url: '/account/editAccount',
        params: data
      }).then(response => {
        if (response.data.code !== 200) {
          this.$message.error(response.data.msg)
        }
      })
    },
    close() {
      this.queryAccountList()
    },
    cellStyleChange(val) {
      if (val.row.sourceType == 2) {
        return {color: "#6395f9"}
      }
    },
  },

}
</script>

<style scoped>

</style>