<template>
  <el-dialog
      title="新增账户"
      :visible.sync="show"
      width="30%"
      @close="close"
      center>
    <el-row>
      <el-form :model="accountForm" :rules="rules" ref="accountForm" label-width="100px" style="width: 90%;">
        <el-form-item label="结算账户" prop="settlementAccount">
          <el-input v-model="accountForm.settlementAccount" placeholder="如：上海招商银行"></el-input>
        </el-form-item>
        <el-form-item label="账户类型" prop="accountType">
          <el-select v-model="accountForm.accountType" placeholder="请选择账户类型" style="width: 100%;" clearable>
            <el-option
                v-for="item in accountTypeArray"
                :key="item.value"
                :label="item.name"
                :value="item.value">
            </el-option>
            <span slot="empty">
              <div  style="font-size: 15px; color: #c0c4cc; text-align: center; padding: 10px">
                您还未添加账户类型，<br>
                请前往 账户管理-账户类型列表 添加
              </div>
            </span>
          </el-select>
        </el-form-item>
        <el-form-item label="账户户名" prop="accountName">
          <el-input v-model="accountForm.accountName" placeholder="如：XX 备用金"></el-input>
        </el-form-item>
        <el-form-item label="结算账号" prop="accountNumber">
          <el-input v-model="accountForm.accountNumber" placeholder="如：银行卡号等" ></el-input>
        </el-form-item>
        <el-form-item label="所属店铺" prop="shopId">
          <el-select v-model="accountForm.shopId" placeholder="请选择店铺类型" style="width: 100%;" clearable>
            <el-option
                v-for="item in shopArray"
                :key="item.value"
                :label="item.name"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </el-row>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="addAccount">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: "account-add",
  props: {
    visible: {
      required: true,
      default: false,
      type: Boolean
    }
  },
  computed: {
    show: {
      get() {
        return this.visible
      },
      set(value) {
        this.$emit('update:visible', value)
      }
    }
  },
  created() {
    this.queryShopIds()
    this.queryAccountTypeIds()
  },
  data() {
    return {
      accountTypeArray: [],
      shopArray: [{
        'name': '全部店铺',
        'value': 0
      }],
      accountForm: {
        settlementAccount: '',
        accountType: '',
        accountName: '',
        accountNumber: '',
        shopId: '',
      },
      rules: {
        settlementAccount: [
          { required: true, message: '请填写结算账户', trigger: 'change' }
        ],
        accountType: [
          { required: true, message: '请选择账户类型', trigger: 'blur' }
        ],
        shopId: [
          { required: true, message: '请选择所属店铺', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    close() {
      this.show = false
      this.$refs['accountForm'].resetFields()
    },
    queryShopIds() {
      this.$selectUtils.shopIdsIsValid().then(response => {
        this.shopArray.push(...JSON.parse(response.data.data))
      })
    },
    queryAccountTypeIds() {
      this.$selectUtils.queryAccountTypeIds().then(response => {
        this.accountTypeArray = JSON.parse(response.data.data)
      })
    },
    addAccount() {
      this.$refs['accountForm'].validate((valid) => {
        if (valid) {
          this.$axios({
            method: 'POST',
            url: '/account/addAccount',
            params: {
              ...this.accountForm,
              tenantCrop: localStorage.getItem('tenantCrop')
            }
          }).then(response => {
            if (response.data.code === 200) {
              this.$message.success('账户添加成功')
              this.show = false
              this.$emit('close')
            } else {
              this.$message.error(response.data.msg)
            }
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style scoped>

</style>