import { render, staticRenderFns } from "./account-edit.vue?vue&type=template&id=4be0b7de&scoped=true&"
import script from "./account-edit.vue?vue&type=script&lang=js&"
export * from "./account-edit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4be0b7de",
  null
  
)

export default component.exports